@tailwind base;
@tailwind components;
@tailwind utilities;




@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
  font-weight: 400;
  overflow-x: hidden;
}

.mainwhoami {
  background: #151418;
  background-image: url('../public/blob-haikei.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  transition: 0.2s ease-in;

}

ul,
ol {
  list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

img,
video {
  display: block;
  max-width: 100%;
}

.btn {
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  outline: none;
}

.btn-shadow {
  box-shadow: 0px 2px 5px rgba(160, 170, 180, 0.6);

}

.btn-shadow:hover {
  box-shadow: 0px 3px 8px rgba(160, 170, 180, 0.9);
}

.PrimaryBtn {
  padding: 12px 20px;
  border: none;
  background: rgb(57, 134, 250);
  color: #f6f6f6;
}

.SecondaryBtn {
  padding: 10px 18px;
  border: 2px solid rgb(57, 134, 250);
  color: #010606;
  margin-right: 1rem;
}

.PrimaryBtn:hover {
  background: rgb(18, 111, 250);
}

.SecondaryBtn:hover {
  background: #e7f1fa;
  color: #000
}

.Container {
  max-width: 1000px;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.BigCard {
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 1rem;
  /* display: flex;
  flex-direction: column; */
  background: #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.AboutBio {
  text-align: justify;
  max-width: 750px;
  margin: 0 auto;
  color: #151418;
  line-height: 1.6;
}

.tagline2 {
  text-align: left;
  max-width: 650px;
  margin: 0;
  color: #151418;
  line-height: 1.6;
}

.tagline2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.SectionTitle {
  font-size: 28px;
  color: #151418;
  font-weight: 700;
  margin-bottom: 3.5rem;
}

.ProjectWrapper {
  margin-top: -1rem;
}

/* ------------------------------------ Media for Container */
@media (max-width: 1000px) {
  .Container {
    padding: 0;
  }
}

/*PROFİLE*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Inter' !important;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

/* Scrollbar */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #585965 #e0e0e0;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #4a5975;
}

*::-webkit-scrollbar-thumb:hover {
  background: #272f56;
}

*::-webkit-scrollbar-thumb {
  background-color: #30345d;
  border-radius: 3px;
  /* border: 1px solid #4c4d56; */
}